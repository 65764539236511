<template>
  <div>
    <!-- <v-row dense
      ><v-col cols="6"> </v-col
      ><v-col class="d-flex">
        <v-btn text color="grey lighten-1" @click="$router.go(-1)"
          >Cancel</v-btn
        >
        <v-btn depressed color="primary" @click="$emit('submit')">Save</v-btn>
      </v-col></v-row
    > -->

    <v-row dense
      ><v-col cols="12" md="6">
        <base-field-input
          v-model="task.attributes.title"
          :label="$tc('workOrder.subWorkOrder', 1) + '#'"
      /></v-col>
      <v-col cols="12" md="6">
        <base-autocomplete
          v-model="task.attributes.owners"
          multiple
          small-chips
          :label="$tc('user.worker', 2)"
          :items="users"
          :item-text="fullName"
          item-value="id"
        />
      </v-col>
      <!-- <v-col>
        <base-selector
          v-model="task.attributes.outgoingShipping"
          label="Outgoing Shipments"
          :items="saleOrders"
          item-text="attributes.customAttributes.name"
          item-value="id"
          disabled
          @input="updateDestination"
        />
      </v-col> -->
    </v-row>
    <v-textarea
      v-model="task.attributes.details"
      outlined
      :label="$t('common.details')"
      rows="3"
    >
    </v-textarea>
    <!--<v-row dense>
      <v-col>
        <base-selector
          label="Blockers"
          :items="purchaseOrders"
          item-text="attributes.customAttributes.name"
          item-value="id"
          multiple/></v-col
    >
    </v-row>-->

    <v-row dense
      ><v-col cols="12" md="6">
        <base-date-picker
          v-model="task.attributes.estimatedStartDate"
          :label="$t('workOrder.estimatedStartingDate')"
        /> </v-col
      ><v-col cols="12" md="6">
        <base-date-picker
          v-model="task.attributes.estimatedEndDate"
          :label="$t('workOrder.estimatedEndDate')"
        />
      </v-col>
      <v-col><base-file-input resource-type="workTask"/></v-col>
    </v-row>
  </div>
</template>

<script>
import { ORGANIZATION_ID } from '@/common/config.js'
export default {
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      saleOrders: [],
      users: []
    }
  },

  async created() {
    this.users = await this.filterUsers({
      roles: 'worker',
      organizationId: ORGANIZATION_ID()
    })
    this.saleOrders = await this.filterSaleOrders()
  },
  methods: {
    async filterSaleOrders() {
      return await this.$store.dispatch('saleOrder/filter')
    },
    async filterUsers(params) {
      return await this.$store.dispatch('user/filter', params)
    },
    fullName(user) {
      if (!user?.attributes?.info) return
      const { name, firstName, lastName } = user.attributes.info
      return name || `${firstName} ${lastName}`
    }
  }
}
</script>

<style lang="scss" scoped></style>
