import { ORGANIZATION_ID } from '@/common/config.js'
import store from '@/store/index.js'

export const TASK = workTaskGroupId => ({
  type: 'workTask',
  attributes: {
    workTaskGroupId,
    taskType: 'task',
    title: '',
    details: null,
    blocker: null,
    requester: store.state.auth.authUser.data.id,
    owners: [],
    deliveredTo: {
      companyName: '',
      location: {},
      estimatedUnloadDateTo: null
    },
    outgoingShipping: null,
    purchaseOrders: [],
    status: 'pending',
    inputItems: [],
    outputItems: [],
    workTime: 0,
    estimatedWorkTime: 0,
    startDate: null,
    endDate: null,
    organizationId: ORGANIZATION_ID(),
    estimatedStartDate: null,
    estimatedEndDate: null
  }
})
