import { render, staticRenderFns } from "./TaskEdit.vue?vue&type=template&id=04b80573&scoped=true&"
import script from "./TaskEdit.vue?vue&type=script&lang=js&"
export * from "./TaskEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04b80573",
  null
  
)

export default component.exports