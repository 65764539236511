<template>
  <base-form-layout
    :title="`${action} ${$tc('workOrder.subWorkOrder', 1)}`"
    size="large"
    @submit="onSubmit"
  >
    <v-form ref="form">
      <TaskEditForm :task="task" />
    </v-form>
  </base-form-layout>
</template>

<script>
import TaskEditForm from './components/TaskEditForm'
import { TASK } from '@/models/task'
import { mapActions } from 'vuex'
export default {
  components: {
    TaskEditForm
  },
  props: {
    taskGroupId: {
      type: String,
      default: ''
    },
    taskId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      task: TASK(this.taskGroupId)
    }
  },
  computed: {
    action() {
      return this.$t(this.taskId ? 'actions.edit' : 'actions.create')
    }
  },
  watch: {
    $route: {
      immediate: true,
      async handler() {
        if (this.taskId) this.task = await this.fetchTask(this.taskId)
        if (!this.taskId && this.taskGroupId) {
          const taskGroup = await this.fetchTaskGroup(this.taskGroupId)
          this.assingDataToTask(taskGroup)
        }
      }
    }
  },

  methods: {
    ...mapActions({
      addNotification: 'notification/add'
    }),
    async fetchTask(taskId) {
      return await this.$store.dispatch('task/fetch', taskId)
    },
    async fetchTaskGroup(taskGroupId) {
      const { data } = await this.$store.dispatch(
        'taskGroup/fetch',
        taskGroupId
      )
      return data
    },
    assingDataToTask(taskGroup) {
      const {
        outgoingShipping,
        purchaseOrders,
        title,
        deliveredTo
      } = taskGroup.attributes
      const { data } = taskGroup.relationships.workTasks
      const titleNumber = data.length + 1
      const autoTitle = title + '-' + titleNumber
      Object.assign(this.task.attributes, {
        title: autoTitle,
        outgoingShipping,
        purchaseOrders,
        deliveredTo
      })
    },
    async onSubmit() {
      try {
        const action = this.taskId ? 'update' : 'create'
        await this.$store.dispatch(`task/${action}`, this.task)

        this.addNotification({
          status: '200',
          color: 'success',
          time: 2000,
          messages: { title: `Successfully ${action}d` }
        })
        this.$router.push({
          name: 'task-group-show',
          params: { taskGroupId: this.taskGroupId }
        })
      } catch (error) {
        if (!error.response) return
        const {
          status,
          data: { errors }
        } = error.response
        return this.addNotification({
          status,
          messages: errors,
          color: 'error',
          time: 10000
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
